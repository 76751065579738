import React, { useState } from 'react';
import styled from 'styled-components';

// Styles
const TextFieldWrap = styled.div<{ focused?: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 34px;
    margin: 5px 0;
    border-bottom: ${({ theme, focused }) => `1px solid ${focused ? theme.color.primary : theme.color.label}`};
`;
const Input = styled.input`
    padding-top: 10px;
    border: none;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: ${({ theme }) => theme.color.primary};
`;
const Label = styled.label<{ focused?: boolean }>`
    cursor: pointer;
    font-weight: 300;
    font-size: ${({ focused }) => (focused ? '11px' : '20px')};
    line-height: 1;
    position: absolute;
    color: ${({ theme }) => theme.color.label};
    left: 0;
    top: ${({ focused }) => (focused ? '-5px' : '50%')};
    transform: ${({ focused }) => (!focused && 'translateY(-50%)')};
    transition: ${({ theme }) => `all ${theme.transition.fast}`};
`;

// Interfaces
interface ITextField extends React.HTMLProps<HTMLInputElement> {
    label?: string
}

// Component
export const TextField = (props: ITextField) => {
    const {
        name,
        type,
        label,
        onChange,
        required,
        autoFocus,
        value,
    } = props;

    const [focused, setFocused] = useState<boolean>(!!value);

    const id = `${name}-${Math.random()}`;

    const handleFocus = () => setFocused(true);
    const handleBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
        if (!ev.target.value) {
            setFocused(false);
        }
    };

    return (
        <TextFieldWrap
            focused={focused}
        >
            <Input
                type={type}
                name={name}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required={required}
                value={value}
                autoFocus={autoFocus}
                id={id}
            />
            {label && <Label htmlFor={id} focused={focused}>{label}</Label>}
        </TextFieldWrap>
    );
};

TextField.defaultProps = {
    label: 'text',
};
