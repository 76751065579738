import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ITheme } from '../../themes/constants/themes';

// Styles
const NavButton = styled.button<{
    isActive: boolean,
    fontWeight?: string | number,
    fontSize?: string,
    borderRadius?: string,
    activeColor?: string
    activeBackground?: string
    borderColor?: string
}>`
    width: 100%;
    height: 40px;
    padding: 10px;
    white-space: nowrap;
    line-height: 20px;
    font-weight: ${({ fontWeight }) => (fontWeight)};
    font-size: ${({ fontSize }) => (fontSize)};
    border-radius: ${({ borderRadius }) => borderRadius};
    background: ${({ isActive, activeBackground }) => (isActive ? activeBackground : 'transparent')};
    border-width: 1px;
    border-style: solid;
    border-color: ${({ borderColor }) => borderColor};
    user-select: none;
    color: ${({ theme, activeColor, isActive }) => (isActive ? activeColor : theme.color.buttonMinor)};
`;

// Interfaces
export enum ThemeTypes {
    NAV_INSIDE = 'navInside',
    NAV_OUTSIDE = 'navOutside',
}
interface ITabsButton {
    title: string
    isActive: boolean
    onClick?: () => void
    themeType: ThemeTypes
    className?: string
}
interface ICurrentThemes {
    contentBackground: string
    contentPadding?: string | number
    navMargin?: string | number
    navSpaceBetween?: number
    buttonBorderRadius?: string
    buttonFontWeight?: number | string
    buttonActiveBackground?: string
    buttonActiveColor?: string
    buttonFontSize?: string
    borderColor?: string
}

// Component
export const TabsButton = (props: ITabsButton) => {
    const {
        title,
        themeType,
        onClick,
        isActive,
        className,
    } = props;

    const theme = useTheme() as ITheme;

    const defaultThemes = {
        contentBackground: 'transparent',
        contentPadding: 0,
        navMargin: '0 -20px 20px',
        navSpaceBetween: 10,
        buttonBorderRadius: theme.borderRadius.button,
        buttonActiveBackground: theme.palette.primary,
        buttonActiveColor: theme.palette.white,
        buttonFontSize: '14px',
        buttonFontWeight: 400,
        borderColor: isActive ? theme.palette.primary : '#f1f1f1',
    };
    const currentThemes: () => ICurrentThemes = () => {
        switch (themeType) {
            case ThemeTypes.NAV_INSIDE: return {
                ...defaultThemes,
            };
            case ThemeTypes.NAV_OUTSIDE: return {
                contentBackground: theme.background.card,
                contentPadding: '20px',
                navMargin: 0,
                navSpaceBetween: 0,
                buttonBorderRadius: `${theme.borderRadius.button} ${theme.borderRadius.button} 0 0`,
                buttonActiveBackground: theme.palette.white,
                buttonActiveColor: theme.palette.primary,
                buttonFontSize: '12px',
                buttonFontWeight: 500,
                borderColor: 'transparent',
            };
            default: return {
                ...defaultThemes,
            };
        }
    };

    return (
        <NavButton
            isActive={isActive}
            fontWeight={currentThemes().buttonFontWeight}
            borderRadius={currentThemes().buttonBorderRadius}
            activeBackground={currentThemes().buttonActiveBackground}
            activeColor={currentThemes().buttonActiveColor}
            fontSize={currentThemes().buttonFontSize}
            borderColor={currentThemes().borderColor}
            onClick={onClick}
            className={className}
        >
            {title}
        </NavButton>
    );
};

TabsButton.defaultProps = {
    onClick: () => {},
    className: '',
};
