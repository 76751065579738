import React, { ElementType, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import find from 'lodash.find';
import { useAppSelector } from '../../hooks/redux';
import { IMenuSubItem } from '../../constants/menus';
import { ReactComponent as ChevronBottomIcon } from '../../media/icons/chevron-bottom.svg';
import RoutePath from '../../routes/constants/routes';
import Breakpoints from '../../themes/constants/breakpoints';
import getSubMenus from '../../helpers/getSubMenus';

// Styles
const MenuItem = styled.div<{ isActive: boolean }>`
    cursor: pointer;
    padding: 8px 30px 8px 40px;
    color: ${({ theme, isActive }) => (isActive ? theme.palette.primary : theme.palette.black)};
    font-size: 16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
    @media only screen and (min-height: 510px) {
        padding: 10px 30px 10px 40px;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 24px;
        padding: 18px 30px 18px 40px;
    }
`;
const MenuItemIcon = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: 20px;
    & > svg{
        width: 20px;
        height: 20px;
        max-width: 20px;
        max-height: 20px;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        width: 36px;
        height: 36px;
        margin-right: 25px;
        & > svg{
            height: 36px;
            width: 36px;
            max-width: 36px;
            max-height: 36px;
        }
    }
`;
const SubMenuList = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 30px;
`;
const SubMenuItem = styled.div<{ isActive: boolean }>`
    font-size: 14px;
    line-height: 18px;
    padding: 6px 10px;
    cursor: pointer;
    user-select: none;
    color: ${({ isActive, theme }) => (isActive ? theme.palette.primary : theme.color.primary)};
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 22px;
        line-height: 28px;
    }
`;
const ChevronIcon = styled(ChevronBottomIcon)<{ open: boolean }>`
    color: inherit;
    margin-left: 20px;
    transform: ${({ open }) => open && 'rotate(180deg)'};
`;

// Interfaces
interface IHeaderMobileMenuItem {
    title: string
    path: RoutePath
    icon: ElementType
    handleRedirect: (path: string) => void
    hasSubMenus: boolean
    setOpen: (open: boolean) => void
}

// Component
export const HeaderMobileMenuItem = (props: IHeaderMobileMenuItem) => {
    const {
        icon,
        title,
        path,
        handleRedirect,
        hasSubMenus = false,
        setOpen,
    } = props;

    const [subOpen, setSubOpen] = useState(false);
    const history = useHistory();

    const genres = useAppSelector((state) => state.data.genres);
    const subMenus = getSubMenus(genres);

    const isActive = history?.location.pathname === path;
    const hasCurrentSub = find(subMenus, (item: IMenuSubItem) => history?.location.pathname === item.path);

    useEffect(() => {
        if (hasCurrentSub) {
            setSubOpen(true);
        }
    }, []);

    const handleClick = () => (isActive ? setOpen(false) : handleRedirect(path));

    const Icon = icon;

    const subMenuList = (
        <SubMenuList>
            {subMenus.map((item) => {
                const {
                    title: subMenuTitle,
                    path: subPath,
                    id,
                } = item;

                const isSubActive = history?.location.pathname === subPath;

                return (
                    <SubMenuItem
                        onClick={() => (isSubActive ? setOpen(false) : handleRedirect(subPath))}
                        isActive={isSubActive}
                        key={id}
                    >
                        {subMenuTitle === 'Все категории' ? 'Все' : subMenuTitle}
                    </SubMenuItem>
                );
            })}
        </SubMenuList>
    );

    return (
        <>
            <MenuItem
                isActive={isActive}
                onClick={!hasSubMenus ? handleClick : () => setSubOpen(!subOpen)}
            >
                <MenuItemIcon>
                    <Icon />
                </MenuItemIcon>
                {title}
                {hasSubMenus && <ChevronIcon open={subOpen} />}
            </MenuItem>
            {hasSubMenus && subOpen && subMenuList}
        </>
    );
};
