import React, {
    ElementType, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux';
import RoutePath from '../../routes/constants/routes';
import Breakpoints from '../../themes/constants/breakpoints';

import { ReactComponent as ChevronBottomIcon } from '../../media/icons/chevron-bottom.svg';
import getSubMenus from '../../helpers/getSubMenus';

// Styles
const MenuItem = styled.div<{ isActive: boolean }>`
    cursor: pointer;
    padding: 10px 0;
    height: inherit;
    color: ${({ theme, isActive }) => (isActive ? theme.palette.primary : theme.palette.black)};
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    user-select: none;
    font-size: 12px;
    line-height: 16px;
    &:not(:last-child) {
        margin-right: 30px;
    }
    @media only screen and (${Breakpoints.DESKTOP_S}) {
        font-size: 14px;
        line-height: 18px;
        &:not(:last-child) {
            margin-right: 40px;
        }
    }
`;
const MenuItemIcon = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-right: 12px;
    & > svg{
        max-width: 16px;
        max-height: 16px;
    }
`;
const SubMenuList = styled.div`
    position: absolute;
    top: 70px;
    transform: translateX(-35px);
    width: 180px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 8px 13px;
    background: ${({ theme }) => theme.background.card};
    border-radius: 10px;
    cursor: default;
    z-index: 10000;
`;
const SubMenuItem = styled.div<{ isActive: boolean }>`
    font-size: 12px;
    line-height: 16px;
    padding: 3px 10px;
    cursor: pointer;
    color: ${({ isActive, theme }) => (isActive ? theme.palette.primary : theme.color.primary)};
`;
const ChevronIcon = styled(ChevronBottomIcon)<{ open: boolean }>`
    color: #666;
    margin-left: 7px;
    width: 12px;
    transform: ${({ open }) => open && 'rotate(180deg)'};
`;

// Interfaces
interface IHeaderDesktopMenuItem {
    title: string
    path: RoutePath
    icon: ElementType
    handleRedirect: (path: string) => void
    hasSubMenus: boolean
}

// Component
export const HeaderDesktopMenuItem = (props: IHeaderDesktopMenuItem) => {
    const {
        icon,
        title,
        path,
        handleRedirect,
        hasSubMenus = false,
    } = props;

    const [subOpen, setSubOpen] = useState(false);

    const history = useHistory();

    const isActive = history?.location.pathname === path;

    const menuBtnRef = useRef<HTMLDivElement>(null);
    const submenuListRef = useRef<HTMLDivElement>(null);

    const Icon = icon;

    const genres = useAppSelector((state) => state.data.genres);
    const subMenus = getSubMenus(genres);

    useEffect(() => {
        const listener = (event: MouseEvent) => {
            if (menuBtnRef.current) {
                if (!menuBtnRef.current.contains(event.target as Node)) {
                    setSubOpen(false);
                }
            }
        };
        window.addEventListener('click', listener);
        return () => window.removeEventListener('click', listener);
    }, [subOpen]);

    const subMenuList = (
        <SubMenuList
            ref={submenuListRef}
        >
            {subMenus.map((item) => {
                const {
                    title: subMenuTitle,
                    path: subPath,
                    id,
                } = item;

                const isSubActive = history?.location.pathname === subPath;

                return (
                    <SubMenuItem
                        onClick={() => handleRedirect(subPath)}
                        isActive={isSubActive}
                        key={id}
                    >
                        {subMenuTitle === 'Все категории' ? 'Все' : subMenuTitle}
                    </SubMenuItem>
                );
            })}
        </SubMenuList>
    );

    return (
        <>
            <MenuItem
                isActive={isActive}
                ref={menuBtnRef}
                onClick={!hasSubMenus ? () => handleRedirect(path) : () => setSubOpen(!subOpen)}
            >
                <MenuItemIcon>
                    <Icon />
                </MenuItemIcon>
                {title}
                {hasSubMenus && <ChevronIcon open={subOpen} />}
                {hasSubMenus && subOpen && subMenuList}
            </MenuItem>
        </>
    );
};
