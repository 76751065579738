import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { ProductTile } from 'components';
import Breakpoints from '../../themes/constants/breakpoints';
import { ShortContentFragment } from '../../generated/graphql';

// Styles
const RecommendsWrapper = styled.div`
    padding: 35px 20px 30px;
    @media only screen and (${Breakpoints.LAPTOP}) {
        padding-left: 0;
        padding-right: 0;
    }
`;
const RecommendsList = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: 0;
    cursor: default;
    user-select: none;
    font-family: ${({ theme }) => theme.fontFamily.serif};
    font-weight: 600;
    margin-bottom: 30px;
    font-size: 24px;
    margin-right: auto;
`;
const CustomProductTile = styled(ProductTile)`
    flex-shrink: 0;
    &:not(:last-child) {
        margin-right: 20px;
    }
`;

// Component
export const Recommends = ({ data, className }: { data: ShortContentFragment[]; className?: string }) => {
    const isLaptopE = useMediaQuery({ query: `(${Breakpoints.LAPTOP_E})` });
    const isLaptopL = useMediaQuery({ query: `(${Breakpoints.LAPTOP_L})` });
    const isLaptop = useMediaQuery({ query: `(${Breakpoints.LAPTOP})` });
    const isTablet = useMediaQuery({ query: `(${Breakpoints.TABLET})` });
    const is3Slides = useMediaQuery({ query: '(min-width: 490px)' });
    const is4Slides = useMediaQuery({ query: '(min-width: 660px)' });

    const [count, setCount] = useState(2);

    useEffect(() => {
        if (isLaptopL) setCount(7);
        else if (isLaptopE) setCount(6);
        else if (isLaptop) setCount(5);
        else if (isTablet) setCount(3);
        else if (is4Slides) setCount(4);
        else if (is3Slides) setCount(3);
        else setCount(2);
    }, [isLaptopL, isLaptopE, is3Slides, is4Slides, isTablet, isLaptop]);

    const dataForRender = data.slice(0, count);

    return (
        <RecommendsWrapper className={className}>
            <Title>
                Вам может понравиться
            </Title>
            <RecommendsList>
                {dataForRender?.map(({
                    id,
                    name,
                    previews,
                    top,
                    readed,
                    link,
                }) => (
                    <CustomProductTile
                        key={id}
                        id={id}
                        link={link}
                        isSlide={false}
                        name={name}
                        previews={previews}
                        top={top}
                        readed={readed}
                    />
                ))}
            </RecommendsList>
        </RecommendsWrapper>
    );
};

Recommends.defaultProps = {
    className: '',
};
