import { useEffect } from 'react';

function useResize() {
    useEffect(() => {
        const resizeListener = () => {
            const vh = window.innerHeight * 0.01;
            const isSupportSVH = ('CSS' in window && 'supports' in CSS) && CSS.supports('height', '100svh');
            document.documentElement.style.setProperty('--vh', isSupportSVH ? '1svh' : `${vh}px`);
        };

        resizeListener();

        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);
}

export default useResize;
