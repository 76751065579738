function convertRussianToLatin(text: string, cutBy?: number): string {
    const cyrillicToLatin: Record<string, string> = {
        а: 'a',
        б: 'b',
        в: 'v',
        г: 'g',
        д: 'd',
        е: 'e',
        ё: 'yo',
        ж: 'zh',
        з: 'z',
        и: 'i',
        й: 'y',
        к: 'k',
        л: 'l',
        м: 'm',
        н: 'n',
        о: 'o',
        п: 'p',
        р: 'r',
        с: 's',
        т: 't',
        у: 'u',
        ф: 'f',
        х: 'h',
        ц: 'ts',
        ч: 'ch',
        ш: 'sh',
        щ: 'sch',
        ъ: '',
        ы: 'y',
        ь: '',
        э: 'e',
        ю: 'yu',
        я: 'ya',
    };

    let latinText: string = text.toLowerCase().replace(/[а-яё]/g, (letter) => cyrillicToLatin[letter]);
    latinText = latinText.replace(/\s+/g, '_');
    return latinText.slice(0, cutBy || 10);
}

export default convertRussianToLatin;
