import React from 'react';
import styled from 'styled-components';
import { Loader } from 'components';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';

// Styles
const LoaderWrap = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
`;

// Component
export const Preloader = () => (
    <DefaultLayout
        header={{ title: ' ' }}
    >
        <LoaderWrap>
            <Loader />
        </LoaderWrap>
    </DefaultLayout>
);
