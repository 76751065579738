import find from 'lodash.find';
import React from 'react';
import Select, { CommonProps } from 'react-select';
import { ISelectFieldOption } from '../../redux/types';

// Styles
const selectStyles = {
    control: (provided: object) => ({
        ...provided,
        fontFamily: 'IBM Plex Sans',
        borderRadius: '0',
        border: 'none',
        borderBottom: '2px solid #C4C4C4',
        color: '#999',
        fontSize: '18px',
        height: '50px',
        fontWeight: 300,
        lineHeight: '20px',
        cursor: 'pointer',
        ':hover': { borderBottom: '2px solid #C4C4C4' },
    }),
    container: (provided: object) => ({
        ...provided,
        marginBottom: '0',
        border: 'none',
    }),
    indicatorSeparator: (provided: object) => ({
        ...provided,
        display: 'none',
    }),
    menu: (provided: object) => ({
        ...provided,
        marginTop: '0',
        borderRadius: '0',
        padding: '17px 0',
        border: 'none',
        boxShadow: '0 4px 10px hsl(0deg 0% 0% / 10%)',
        '@media only screen and (min-width: 768px)': {
            borderRadius: '0 0 20px 20px',
            border: 'none',
        },
    }),
    option: (provided: object) => ({
        ...provided,
        fontSize: '18px',
        fontWeight: 700,
        fontFamily: 'IBM Plex Sans',
        lineHeight: '20px',
        cursor: 'pointer',
        padding: '10px 20px',
        color: '#000',
        '@media only screen and (min-width: 768px)': {
            fontWeight: 400,
        },
    }),
    singleValue: (provided: object) => ({
        ...provided,
        fontWeight: 700,
    }),
    clearIndicator: (provided: object) => ({
        ...provided,
        color: '#000',
    }),
    dropdownIndicator: (provided: object, state:CommonProps<any, any>) => ({
        ...provided,
        color: `${state.selectProps.menuIsOpen ? '#EC524B' : '#000'}`,
        transform: `${state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'}`,
        transition: '0.5s ease all',
        ':hover': { color: 'none' },
    }),
};

// Interfaces
interface ISelectField {
    placeholder: string;
    options: ISelectFieldOption[] | [];
    name: string;
    onChange: (option: ISelectFieldOption) => void;
    value?: string | string[] | null;
}

// Component
export const SelectField = ({
    placeholder,
    name,
    options,
    value,
    onChange,
}: ISelectField) => {
    const selectedValue = find(options, ((item: ISelectFieldOption) => item.value === value));

    return (
        <Select
            styles={selectStyles}
            options={options}
            value={selectedValue}
            name={name}
            placeholder={placeholder}
            isClearable
            onChange={onChange}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: 'transparent',
                },
            })}
        />
    );
};

SelectField.defaultProps = {
    value: '',
};
