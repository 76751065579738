import React, { useEffect, useMemo, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import useAddToHomeScreen from 'hooks/useAddToHomeScreen';
import { checkIsIOS, checkIsInStandaloneMode } from 'helpers/platform';
import { ReactComponent as DownloadIcon } from 'media/icons/pwa-download.svg';
import { ReactComponent as AddTextIcon } from 'media/icons/pwa-add-text.svg';
import { ReactComponent as XIcon } from 'media/icons/x.svg';
import { ReactComponent as XCircleIcon } from 'media/icons/x-circle.svg';
import { ReactComponent as IOSMenuIcon } from 'media/icons/ios-menu.svg';
import Breakpoints from 'themes/constants/breakpoints';
import { useMediaQuery } from 'react-responsive';

const popupAnimation = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.5) translateX(50%);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateX(0);
    }
`;
const buttonAnimation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.85);
    }
    100% {
        transform: scale(1);
    }
`;
const buttonMaskAnimation = keyframes`
    0% {
        transform: translate(-50%, -50%) rotate(0) scale(0.85);
    }
    50% {
        transform: translate(-50%, -50%) rotate(180deg) scale(1);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg) scale(0.85);
    }
`;

const Wrap = styled.div`
    pointer-events: all;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: flex-end;
    max-width: calc(100vw - 20px);
    margin: 10px;
    z-index: 101;
    @media only screen and (${Breakpoints.LAPTOP}) {
        position: fixed;
        bottom: 0;
        right: 0;
    }
`;
const Button = styled.div<{ $open?: boolean }>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
    flex-shrink: 0;
    pointer-events: all;
    width: 60px;
    height: 60px;
    background: #7cb553;
    border-radius: 100%;
    cursor: pointer;
    ${({ $open }) => !$open && css`
        animation: ${buttonAnimation} 2s linear infinite;
    `}
    & > svg {
        color: #fff;
        width: 28px;
        height: 28px;
        z-index: 10;
    }
`;
const ButtonMask = styled.div<{ $open?: boolean }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: ${({ $open }) => ($open ? 0 : 0.5)};
    content: "";
    pointer-events: none;
    border-radius: 100%;
    transition: all .3s linear;
    animation: ${buttonMaskAnimation} 2s linear infinite;
    z-index: 10;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 100%;
        width: 110%;
        height: 110%;
        transform: translate(-12px);
        background: #eee;
        z-index: 10;
    }
`;
const ButtonClose = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(5px, -5px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #7cb553;
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    z-index: 10;
    & > svg {
        width: 16px;
        height: 16px;
    }
`;
const Popup = styled.div`
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    pointer-events: all;
    max-width: 350px;
    margin-right: 14px;
    background: linear-gradient(264.26deg, #6cba58 0%, #ee942d 76.62%);
    color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 18px;
    padding: 10px 30px 12px 10px;
    animation: ${popupAnimation} .3s linear;
    &:after {
        content: "";
        position: absolute;
        top: 60%;
        left: calc(100% - 8px);
        transform: translate(0, -50%);
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid #7cb553;
    }
`;
const PopupClose = styled.div`
    position: absolute;
    top: 10px;
    right: 8px;
    cursor: pointer;
    & > svg {
        width: 24px;
        height: 24px;
    }
`;
const PopupCover = styled.img.attrs({ alt: '' })`
    flex-shrink: 0;
    background: #7cb553;
    width: 72px;
    height: 72px;
    margin-right: 5px;
    border-radius: 10px;
`;
const PopupContent = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
`;
const PopupText = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 1.1;
    font-family: ${({ theme }) => theme.fontFamily.addToHomeScreen};
    & > svg {
        margin: 0 5px;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 14px;
    }
`;
const PopupButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex-shrink: 0;
    margin-top: 7px;
    min-width: 95px;
    width: auto;
    height: 30px;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    & > svg {
        width: auto;
        height: 14px;
    }
`;

function AddToHomeScreen() {
    const isLaptop = useMediaQuery({ query: `(${Breakpoints.LAPTOP})` });

    const [show, setShow] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [canClose, setCanClose] = useState<boolean>(false);

    const { prompt, promptToInstall } = useAddToHomeScreen();

    const canInstallApp = !!prompt;
    const isIOS = checkIsIOS();
    const isInStandaloneMode = checkIsInStandaloneMode();
    const canShowModal = canInstallApp || (isIOS && !isInStandaloneMode);

    const handleShow = () => setShow(true);
    const handleHide = () => {
        setShow(false);
        const timeSec = 15 * 60 * 1000;
        setTimeout(() => handleShow(), timeSec);
    };
    const handleInstall = () => {
        promptToInstall();
        handleHide();
    };
    const handlePopupToggle = () => {
        setOpen(!open);
        setCanClose(open);
    };

    useEffect(() => {
        if (canShowModal) handleShow();
    }, [canShowModal]);

    const imgSrc = `${process.env.PUBLIC_URL}/img/icon-192x192.png`;

    const buttonEl = (
        <Button $open={open} onClick={handlePopupToggle}>
            <DownloadIcon />
            {canClose ? (
                <ButtonClose
                    onClick={(ev) => {
                        ev.stopPropagation();
                        handleHide();
                    }}
                >
                    <XCircleIcon />
                </ButtonClose>
            ) : null}
            <ButtonMask $open={open} />
        </Button>
    );

    const serviceName = 'SMARTBOOKS';
    const homePageName = useMemo(() => (isLaptop ? 'рабочий стол' : 'домашний экран'), [isLaptop]);
    const installAppText = `Добавить ярлык «${serviceName}» на ${homePageName}?`;
    const iOSAppText = (
        <>
            {installAppText}
            <br />
            Нажмите на
            <IOSMenuIcon />
            и выберите «На экран «Домой»
        </>
    );
    const text = useMemo(() => (canInstallApp ? installAppText : iOSAppText), [canInstallApp]);

    const popupEl = open ? (
        <Popup>
            <PopupClose onClick={handlePopupToggle}>
                <XIcon />
            </PopupClose>
            <PopupCover src={imgSrc} />
            <PopupContent>
                <PopupText>{text}</PopupText>
                {canInstallApp ? (
                    <PopupButton onClick={handleInstall}>
                        <AddTextIcon />
                    </PopupButton>
                ) : null}
            </PopupContent>
        </Popup>
    ) : null;

    if (show && (canInstallApp || isIOS)) {
        return (
            <Wrap>
                {popupEl}
                {buttonEl}
            </Wrap>
        );
    }

    return null;
}

export default AddToHomeScreen;
