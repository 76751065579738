import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CompilationAliases from '../../../constants/compilations';
import { ISelectsDataState } from '../../types';

const initialState: ISelectsDataState = {
    creators: [],
    compilation: [
        { label: 'Популярное в мире', value: CompilationAliases.POPULAR },
        { label: 'Новинки', value: CompilationAliases.NEW },
        { label: 'Рекомендуем', value: CompilationAliases.RECOMMEND },
        { label: 'Для родителей', value: CompilationAliases.FOR_PARENTS },
    ],
};

export const selectsSlice = createSlice({
    name: 'selects',
    initialState,
    reducers: {
        setCreatorsSelects: (state, action: PayloadAction<ISelectsDataState['creators']>) => {
            // eslint-disable-next-line no-param-reassign
            state.creators = action.payload;
        },
        setCompilationSelects: (state, action: PayloadAction<ISelectsDataState['compilation']>) => {
            // eslint-disable-next-line no-param-reassign
            state.compilation = action.payload;
        },
    },
});

export const { setCreatorsSelects, setCompilationSelects } = selectsSlice.actions;

export default selectsSlice.reducer;
