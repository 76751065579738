import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import { ClassNameFormatter, cn } from '@bem-react/classname';
import closeIcon from './img/close-icon.png';
import './PrivacyPolicy.sass';

export interface PrivacyPolicyProps {
  dangerousText: string;
  adoption?: boolean;
}

interface PrivacyPolicyState {
  portalId: string;
  isHidden: boolean;
  loaded: boolean;
  closed: string | null;
  cnPrivacyPolicy: ClassNameFormatter;
  el: HTMLDivElement;
}

export class PrivacyPolicy extends PureComponent<PrivacyPolicyProps, PrivacyPolicyState> {
  constructor(props: PrivacyPolicyProps) {
    super(props);
    this.state = {
      portalId: 'pp-portal',
      isHidden: false,
      loaded: false,
      closed: sessionStorage.getItem('pp'),
      cnPrivacyPolicy: cn('PrivacyPolicy'),
      el: document.createElement('div'),
    };
  }

  componentDidMount() {
    const { el, portalId } = this.state;

    el.id = portalId;
    el.style.cssText = `
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 100;
        display: flex;
        justify-content: center;
        `;
    document.body.prepend(el);

    this.setState({ loaded: true });
  }

  componentWillUnmount() {
    const { el } = this.state;

    document.body.removeChild(el);
  }

  closeHandler = () => {
    this.setState({ isHidden: true });
    setTimeout(() => {
      this.setState({ loaded: false });
      sessionStorage.setItem('pp', '1');
    }, 300);
  };

  renderElement = () => {
    const { isHidden, cnPrivacyPolicy } = this.state;
    const { dangerousText, adoption } = this.props;

    return (
      <div className={cnPrivacyPolicy()}>
        <div className={cnPrivacyPolicy('Wrapper', { hidden: isHidden, adoption })}>
          <p
            className={cnPrivacyPolicy('P', { adoption })}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: dangerousText }}
          />
          {adoption ? (
            <div className={cnPrivacyPolicy('ButtonWrapper')}>
              <button
                type="button"
                className={cnPrivacyPolicy('ButtonAd')}
                onClick={this.closeHandler}
              >
                Не принимаю
              </button>
              <button
                type="button"
                className={cnPrivacyPolicy('ButtonAd', { accept: true })}
                onClick={this.closeHandler}
              >
                Принимаю
              </button>
            </div>
          ) : (
            <button
              type="button"
              className={cnPrivacyPolicy('Button')}
              onClick={this.closeHandler}
            >
              <img className={cnPrivacyPolicy('CloseIcon')} src={closeIcon} alt="" />
            </button>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { portalId, loaded, closed } = this.state;
    const portalElement = document.getElementById(portalId);

    return closed !== '1' && loaded && portalElement ? createPortal(
      this.renderElement(),
      portalElement,
    ) : null;
  }
}
