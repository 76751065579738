import styled from 'styled-components';
import Breakpoints from '../../themes/constants/breakpoints';

export const Inner = styled.div<{ withoutPaddings?: boolean }>`
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    max-width: ${({ theme }) => theme.width.wrap};
    width: 100%;
    margin: 0 auto;
    ${({ theme, withoutPaddings }) => !withoutPaddings && theme.paddings.primary};
    @media only screen and (${Breakpoints.TABLET}) {
        ${({ theme, withoutPaddings }) => !withoutPaddings && theme.paddings.tablet};
    }
`;
