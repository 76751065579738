import React, { useEffect, useState } from 'react';
import SwiperCore, { Lazy } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import {
    IMainTabs, Inner, Loader, ProductTile, tabs,
} from 'components';
import Breakpoints from '../../themes/constants/breakpoints';
import { LogicOperator, Order, useContentByCategoryQuery } from '../../generated/graphql';

import { ReactComponent as Arrow } from '../../media/icons/arrow.svg';

SwiperCore.use([Lazy]);

// styles
const SectionWrapper = styled.div<{ background?: string }>`
    padding-bottom: 30px;
    @media only screen and (${Breakpoints.TABLET}) {
        padding-bottom: 46px;
    }
`;
const SliderWrapper = styled.div`
    height: 200px;
    position: relative;
    @media only screen and (${Breakpoints.TABLET}) {
        height: 377px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        height: 228px;
    }
`;
const Title = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: 0;
    cursor: pointer;
    font-family: ${({ theme }) => theme.fontFamily.serif};
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    z-index: 10;
    color: ${({ theme }) => theme.color.black};
    @media only screen and (${Breakpoints.TABLET}) {
        margin-bottom: 36px;
        font-size: 48px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        margin-bottom: 20px;
        font-size: 32px;
        margin-right: auto;
    }
`;
const SectionLoader = styled(Loader)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
const StyledArrow = styled(Arrow)`
    margin-left: 15px;
    @media only screen and (${Breakpoints.TABLET}) {
        width: 68px;
        height: 30px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        width: 30px;
        height: 18px;
        margin-left: 30px;
    }
`;
const Slider = styled.div`
    margin: -20px;
    & .swiper-container {
        padding: 20px;
        width: 100%;
    }
    & .swiper-slide {
        width: auto;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        margin: -40px;
        & .swiper-container {
            padding: 40px;
        }
    }
`;

// interfaces
export interface ISliderDefault {
    title?: string;
    titleLink?: string;
    className?: string;
    background?: string;
    categoryId: string;
    filterBy?: IMainTabs['id'];
}

// component
export const SliderDefault = ({
    title,
    titleLink,
    className,
    background,
    categoryId,
    filterBy,
}: ISliderDefault) => {
    const history = useHistory();

    const paramFilter = [{
        paramName: filterBy || tabs[0].id,
        value: ['true'],
    }];

    const {
        data, loading, fetchMore,
    } = useContentByCategoryQuery({
        variables: {
            id: categoryId,
            sort: {
                paramName: 'name',
                order: Order.Asc,
            },
            paramFilters: {
                logicOperator: LogicOperator.And,
                negative: false,
                params: paramFilter,
            },
            filter: {
                typeName: 'book',
            },
        },
        fetchPolicy: 'network-only',
    });

    const [swiperInst, setSwiperInst] = useState<SwiperCore | null>(null);

    useEffect(() => {
        swiperInst?.update();
        swiperInst?.lazy?.load();
    }, [data]);

    const renderSlides = () => data?.point?.service?.category?.contents?.map(({
        name,
        id,
        metaContentParams,
        previews,
        top,
        readed,
        link,
    }) => (
        <SwiperSlide
            key={id}
        >
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
            <ProductTile
                id={id}
                isSlide
                name={name}
                link={link}
                metaContentParams={metaContentParams}
                previews={previews}
                top={top}
                readed={readed}
            />
        </SwiperSlide>
    ));

    const onReachEnd = (e: SwiperCore) => {
        if (fetchMore) {
            fetchMore({
                variables: {
                    offset: e.slides.length,
                    paramFilter,
                },
            }).catch(() => {
            });
        }
    };

    return (
        <SectionWrapper className={className} background={background}>
            <Inner>
                {title && (
                    <Title
                        type="button"
                        onClick={() => history.push(titleLink || '/')}
                    >
                        {title}
                        <StyledArrow />
                    </Title>
                )}
                <SliderWrapper>
                    {!loading ? (
                        <Slider>
                            <Swiper
                                slidesPerView="auto"
                                spaceBetween={10}
                                freeMode
                                onSwiper={setSwiperInst}
                                onReachEnd={onReachEnd}
                                lazy={{
                                    loadPrevNext: true,
                                    loadPrevNextAmount: 5,
                                }}
                                breakpoints={{
                                    768: {
                                        spaceBetween: 16,
                                    },
                                    1024: {
                                        spaceBetween: 50,
                                        lazy: {
                                            loadPrevNext: true,
                                            loadPrevNextAmount: 8,
                                        },
                                    },
                                }}
                            >
                                {renderSlides()}
                            </Swiper>
                        </Slider>
                    ) : <SectionLoader />}
                </SliderWrapper>
            </Inner>
        </SectionWrapper>
    );
};

SliderDefault.defaultProps = {
    title: '',
    titleLink: '',
    className: '',
    background: '',
    filterBy: '',
};
