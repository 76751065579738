import { IMenuSubItem } from '../constants/menus';
import getCategoryAlias from './getCategoryAlias';

const getSubMenus = (genresArray: string[]): IMenuSubItem[] => genresArray
    .map((genre: string, index: number) => ({
        title: genre,
        path: `/categories/${getCategoryAlias(genre)}`,
        id: index,
    }));

export default getSubMenus;
