import React from 'react';
import styled from 'styled-components';
import Breakpoints from '../../themes/constants/breakpoints';

export interface IMainTabs {
    id: 'ebook_exist' | 'abook_exist';
    name: 'Читать' | 'Слушать';
}

export const tabs: IMainTabs[] = [
    {
        id: 'ebook_exist',
        name: 'Читать',
    },
    {
        id: 'abook_exist',
        name: 'Слушать',
    },
];

export interface IMainTabsComp {
    tabsState: IMainTabs['id'];
    setTabsState: (id: IMainTabs['id']) => void;
}

const TabsListWrapper = styled.div`
    position: relative;
    display: flex;
    padding: 0 0 35px;
    z-index: 1;
    ${({ theme }) => theme.paddings.primary};
    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 5px 5px 0 0;
        background: #fff;
        height: 35px;
        z-index: 10;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        ${({ theme }) => theme.paddings.tablet};
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        justify-content: center;
        &:after {
            top: 0;
            height: auto;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 0,
                rgba(255, 255, 255, 1) 60%,
                rgba(255, 255, 255, 1) 100%);
            z-index: -1;
        }
    }
`;
const TabList = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
    @media only screen and (${Breakpoints.LAPTOP}) {
        width: auto;
        list-style: none;
        background: #f2f2f2;
        border-radius: 10px;
    }
`;
const Tab = styled.li<{ active?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 40px;
    background: ${({ active }) => (active ? 'linear-gradient(180deg, #eaeaea 0%, #fff 100%);' : 'transparent')};
    font-size: 18px;
    font-weight: 700;
    color: ${({ active, theme }) => (active ? theme.palette.blueGray : theme.color.buttonMinor)};
    border-radius: 5px 5px 0 0;
    user-select: none;
    cursor: ${({ active }) => (active ? 'default' : 'pointer')};
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 38px;
        border-radius: 10px 10px 0 0;
        height: 86px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        font-size: 18px;
        border-radius: 10px;
        width: 160px;
        height: 60px;
        background: ${({ active, theme }) => (
        active ? theme.palette.primary : '#f2f2f2'
    )};
        color: ${({ active, theme }) => (
        active ? theme.palette.white : theme.palette.black
    )}
    }
`;

export const TabsMain = ({ tabsState, setTabsState }: IMainTabsComp) => (
    <TabsListWrapper>
        <TabList>
            {tabs.map(({ name, id }) => (
                <Tab
                    active={id === tabsState}
                    key={id}
                    onClick={() => {
                        sessionStorage.setItem('tabId', id);
                        setTabsState(id);
                    }}
                >
                    {name}
                </Tab>
            ))}
        </TabList>
    </TabsListWrapper>
);
