import { FiltersType } from 'constants/filters';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useMediaQuery } from 'react-responsive';
import uniqBy from 'lodash.uniqby';
import { TabsButton, SelectField, ThemeTypes } from 'components';
import Breakpoints from 'themes/constants/breakpoints';
import { useTagsQuery } from 'generated/graphql';
import { ReactComponent as ArrowIcon } from 'media/icons/filter-arrow.svg';
import { useAppSelector } from '../../hooks/redux';
import { ISelectFieldOption } from '../../redux/types';

// Styles
const FiltersWrap = styled.div``;
const TabsNav = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: scroll;
    margin: 0 -20px 20px;
    & .swiper-container {
        padding: 0 20px;
        width: 100%;
    }
    & .swiper-slide {
        width: auto;
    }
`;
const SelectsWrap = styled.div`
    position: relative;
    z-index: 11;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    & > *:not(:last-child){
        margin-bottom: 8px;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0 50px;
    }
    @media only screen and (${Breakpoints.LAPTOP_E}) {
        grid-template-columns: repeat(4, 340px);
        grid-gap: 0 20px;
    }
`;
const Title = styled.p<{ isOpen?: boolean }>`
    margin: 0;
    padding: 3px 0 5px;
    color: ${({ theme, isOpen }) => (isOpen ? theme.color.selectedColor : theme.color.black)};
    font-weight: 600;
`;
const ArrowBtn = styled.button<{ isOpen?: boolean }>`
    display: inline;
    background: none;
    border: 0;
    cursor: pointer;
    height: inherit;
    width: 48px;
    svg {
        transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
        transition: 0.2s ease all;
        path {
            fill: ${({ theme, isOpen }) => (isOpen ? theme.color.selectedColor : theme.color.black)};
        }
    }
`;

// Interfaces
interface IFilters {
    type: FiltersType;
    slidesPerView?: number | 'auto' | undefined;
}

// Component
export const Filters = ({ type, slidesPerView }: IFilters) => {
    const history = useHistory();
    const genres = useAppSelector((state) => state.data.genres);

    const allGenresName = 'Все категории';
    const [filtersOpen, setFiltersOpen] = useState(true);
    const { data: tagsData } = useTagsQuery();
    const uniqTags = uniqBy(tagsData?.point?.service.tags, 'displayName') || [];
    const tagsObj: ISelectFieldOption[] | [] = uniqTags
        ?.reduce<ISelectFieldOption[]>((acc, curr) => ([...acc, {
        value: curr.displayName, label: curr.displayName,
    }]), []) || [];

    const isLaptopL = useMediaQuery({ query: `(${Breakpoints.LAPTOP_L})` });

    const isCategories = type === FiltersType.CATEGORIES;
    const isSearch = type === FiltersType.SEARCH;

    const search = history?.location.search || '';
    const searchObj = queryString.parse(search);
    const categoryQuery = searchObj?.category || '';

    const authors = useAppSelector((state) => state.data.authors);

    const datesOptions = [
        { value: 'new', label: 'Сначала новые' },
        { value: 'old', label: 'Сначала старые' },
    ];

    const filtersArr = [
        {
            placeholder: 'По дате добавления',
            name: 'sortBy',
            id: 1,
            options: datesOptions,
            view: true,
        },
        {
            placeholder: 'По автору',
            name: 'author',
            id: 2,
            options: authors,
            view: true,
        },
        {
            placeholder: 'Все подборки',
            name: 'compilation',
            id: 3,
            options: tagsObj,
            view: isCategories,
        },
    ];

    const handleAppendSearch = ({ key, value }: { key: string, value: string }) => ({
        ...searchObj,
        [key]: value,
    });
    const handleSetQuery = ({ key, value }: { key: string, value: string }) => {
        history.push({
            search: queryString.stringify(handleAppendSearch({
                key,
                value,
            })),
        });
    };

    const selects = (
        <SelectsWrap>
            {filtersArr?.map(({
                options, id, placeholder, name, view,
            }) => {
                if (view) {
                    return (
                        <SelectField
                            key={id}
                            name={name}
                            value={searchObj?.[name]}
                            onChange={(option) => {
                                const value = option?.value || '';
                                handleSetQuery({ key: name, value });
                            }}
                            options={options}
                            placeholder={placeholder}
                        />
                    );
                }
                return null;
            })}
        </SelectsWrap>
    );

    const tabs = (
        <TabsNav>
            <Swiper
                slidesPerView={slidesPerView}
                spaceBetween={10}
                freeMode
            >
                {genres?.map((i) => {
                    const isActive = categoryQuery ? (i === categoryQuery) : (i === allGenresName);

                    return (
                        <SwiperSlide key={i}>
                            <TabsButton
                                isActive={isActive}
                                themeType={ThemeTypes.NAV_INSIDE}
                                onClick={() => !isActive && handleSetQuery({
                                    key: 'category',
                                    value: decodeURI(i) !== allGenresName ? i : '',
                                })}
                                title={i}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </TabsNav>
    );

    const title = (
        <Title onClick={() => setFiltersOpen(!filtersOpen)} isOpen={filtersOpen}>
            Фильтры
            <ArrowBtn
                isOpen={filtersOpen}
            >
                <ArrowIcon />
            </ArrowBtn>
        </Title>
    );

    return (
        <FiltersWrap>
            {!isCategories && tabs}
            {(!isLaptopL && !isSearch) && title}
            {((filtersOpen || isLaptopL) && !isSearch) && selects}
        </FiltersWrap>
    );
};

Filters.defaultProps = {
    slidesPerView: 'auto',
};
