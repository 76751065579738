import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { ReactComponent as PrevIcon } from '../../media/icons/prev.svg';
import { ReactComponent as NextIcon } from '../../media/icons/next.svg';
import Breakpoints from '../../themes/constants/breakpoints';

// Styles
const PaginationWrap = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    width: 100%;
    height: ${({ theme }) => theme.height.readerPagination};
    align-items: center;
    padding: 12px 40px 0;
    position: relative;
    font-family: ${({ theme }) => theme.fontFamily.sans};
    font-size: 12px;
    line-height: 20px;
    max-width: 730px;
    margin: auto;
    & > input {
        width: 100%;
    }
`;
const InputWrap = styled.div<{ progress?: number, disabled?: boolean }>`
    position: relative;
    background: #c2c2c2;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    z-index: 10;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        width: ${({ progress }) => `${progress}%`};
        height: 10px;
        background: ${({ theme, disabled }) => !disabled && theme.palette.primary};
        border-radius: 5px;
        z-index: -10;
    }
`;
const Input = styled.input`
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: inherit;
    background: transparent;
    -webkit-appearance: none;
    &:focus {
      outline: none;
    }
    &::-webkit-slider-runnable-track {
        background: transparent;
        border: 0;
        border-radius: 5px;
        width: 100%;
        height: 10px;
        cursor: pointer;
    }
    &::-webkit-slider-thumb {
        margin-top: -5px;
        width: 20px;
        height: 20px;
        background: ${({ theme, disabled }) => (disabled ? '#ccc' : theme.palette.white)};
        border: 0;
        border-radius: 50px;
        cursor: pointer;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
        -webkit-appearance: none;
    }
    @supports (-ms-ime-align:auto) {
        margin: 0;
    }
`;
const PageCount = styled.span`
    margin-top: 5px;
`;

const Prev = styled.button`
    position: absolute;
    left: 0;
    top: 0;
    height: 65px;
    width: 40px;
    background: none;
    text-align: left;
    display: none;
    @media (${Breakpoints.LAPTOP}) {
        display: block;
    }
`;

const Next = styled.button`
    position: absolute;
    right: 0;
    top: 0;
    height: 65px;
    width: 40px;
    background: none;
    text-align: right;
    display: none;
    @media (${Breakpoints.LAPTOP}) {
        display: block;
    }
`;

// Interface
export interface IPaginationSlide {
    value: number,
    maxValue: number,
    disabled?: boolean,
    onChange(number: number): void,
}

// Component
export const ProgressPagination = ({
    value = 1, maxValue = 1, onChange, disabled,
}: IPaginationSlide) => {
    const progress: number = ((100 / (maxValue - 1)) * (value - 1)) || 0;

    const NextPageHandler = () => {
        if (value < maxValue) {
            onChange(value + 1);
        }
    };

    const PrevPageHandler = () => {
        if (value > 1) {
            onChange(value - 1);
        }
    };

    // Render
    return (
        <PaginationWrap>
            {value !== 1
                ? (
                    <Prev
                        type="button"
                        onClick={PrevPageHandler}
                    >
                        <PrevIcon />
                    </Prev>
                ) : null}

            <InputWrap
                progress={progress}
                disabled={disabled}
            >
                <Input
                    type="range"
                    value={value}
                    min={1}
                    disabled={disabled}
                    max={maxValue}
                    onChange={(ev: ChangeEvent<HTMLInputElement>) => onChange(+ev.target.value || 0)}
                />
            </InputWrap>
            {value !== maxValue
                ? (
                    <Next
                        type="button"
                        onClick={NextPageHandler}
                    >
                        <NextIcon />
                    </Next>
                ) : null}

            <PageCount>{`${value} из ${maxValue}`}</PageCount>
        </PaginationWrap>
    );
};

ProgressPagination.defaultProps = {
    disabled: false,
};
