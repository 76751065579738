import React, { useEffect, useState } from 'react';

import { EpubView } from 'react-reader';
import styled, { useTheme } from 'styled-components';
import { Loader, ProgressPagination } from 'components';
import Breakpoints from '../../themes/constants/breakpoints';
import useDebounce from '../../hooks/useDebounce';
import { ITheme } from '../../themes/constants/themes';
import useChangeLibraryType from '../../hooks/useChangeLibraryType';
import { LibraryType, useSetReadingPositionMutation } from '../../generated/graphql';

// Styles
const EpubWrap = styled.div`
    margin: 30px 0 0;
    & .epub-container{
        overflow-x: hidden !important;
    }
    @media (${Breakpoints.LAPTOP}) {
        margin: 0;
    }
`;
const EpubViewWrapper = styled.div`
    height: ${({ theme }) => `calc((var(--vh) * 100) - (${theme.height.readerPagination} + 30px))`};
    @media (${Breakpoints.LAPTOP}) {
        height: ${({ theme }) => `calc((var(--vh) * 100) - (${theme.height.readerPagination}
         + ${theme.height.header.tablet} + 40px))`};
        overflow: hidden;
        background: white;
        margin-top: 40px;
        padding-top: 35px;
        padding-bottom: 35px;
        filter: drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.1));
        border-radius: 20px;
    }
    @media (${Breakpoints.LAPTOP_L}) {
        height: ${({ theme }) => `calc((var(--vh) * 100) - (${theme.height.readerPagination}
         + ${theme.height.header.desktop} + 40px))`};
    }
`;
const LoaderWrap = styled.div`
    display: flex;
    align-items: center;
    height: ${({ theme }) => `calc((var(--vh) * 100) - (${theme.height.readerPagination} + 30px))`};
`;
const epubViewStyles = {
    header: {
        padding: 0,
        margin: 0,
    },
    body: {
        'font-family': '"IBM Plex Sans",Arial,sans-serif',
        'font-size': '18px',
        'line-height': 1.2,
        padding: '0 40px !important',
    },
    '*': {
        margin: '0 0 10px',
        'font-size': '18px',
        'text-align': 'left !important',
    },
    'h1, h2, h3, h4, h5, h6': {
        'font-size': '18px',
        'font-weight': 700,
        'text-align': 'left',
        'font-family': '"IBM Plex Sans", Arial, sans-serif',
    },
    p: {
        'font-size': '18px',
        'font-family': '"IBM Plex Sans", Arial, sans-serif',
    },
    blockquote: {
        margin: '0 20px !important',
    },
    'blockquote *': {
        'font-style': 'italic',
        'font-weight': 400,
    },
    table: {
        'max-width': '100% !important',
        width: '100% !important',
    },
};

// Interfaces
export interface IEpubReader {
    bookUrl: string;
    bookId: string;
    setIsReadyStatus?(bool: boolean): void;
    position: number;
    isReaded: boolean;
    isReadNow: boolean;
}

// Component
export const EpubReader = (props: IEpubReader) => {
    const {
        bookUrl, bookId, setIsReadyStatus, position, isReaded, isReadNow,
    } = props;

    const readerHeightInit: { page: number, scroll: number } = {
        page: 0,
        scroll: 0,
    };
    const [pageCount, setPageCount] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [readerHeight, setReaderHeight] = useState(readerHeightInit);
    const [percentage, setPercentage] = useState<number>(0);
    const debouncedPercentage = useDebounce(percentage, 1000);

    const pageHeight = readerHeight.page;
    const scrollHeightReader = readerHeight.scroll;
    const isReady = pageHeight !== 0 && scrollHeightReader !== 0;

    const epubView = document.getElementsByClassName('epub-container');

    const theme = useTheme() as ITheme;

    const [setPosition] = useSetReadingPositionMutation();

    const [addReadNowType, removeReadNowType] = useChangeLibraryType({
        id: bookId,
        type: LibraryType.ReadNow,
    });

    const [addReadedType, removeReadedType] = useChangeLibraryType({
        id: bookId,
        type: LibraryType.Readed,
    });

    useEffect(() => {
        if (isReady) {
            setPosition({
                variables: {
                    id: bookId,
                    position: debouncedPercentage,
                },
            })
                .catch(() => {});
            if (debouncedPercentage === 100 && !isReaded) addReadedType();
            if (debouncedPercentage === 100 && isReadNow) removeReadNowType();
            if (debouncedPercentage !== 100 && isReaded) removeReadedType();
            if (debouncedPercentage !== 100 && !isReadNow) addReadNowType();
        }
    }, [debouncedPercentage, isReaded, isReadNow]);

    useEffect(() => {
        const el = epubView[0];
        if (position && el) {
            el.scrollTop = (position * (el.scrollHeight - el.clientHeight)) / 100;
        }
    }, [pageCount, epubView]);

    useEffect(() => {
        const truePages: number = (scrollHeightReader / pageHeight) || 1;
        setPageCount(Math.ceil(truePages));
        if (setIsReadyStatus) {
            setIsReadyStatus(isReady);
        }
    }, [readerHeight]);

    useEffect(() => {
        const el = epubView[0];
        const listener = () => {
            const { scrollTop } = el;
            const newPage: number = Math.ceil((scrollTop / pageHeight) + 1) || 1;
            if (newPage !== currentPage) {
                setCurrentPage(newPage);
            }
        };
        el?.addEventListener('scroll', listener);
        return () => el?.removeEventListener('scroll', listener);
    }, [pageCount, currentPage]);

    const handleChangePage = (num: number) => {
        setCurrentPage(num);
        epubView[0]?.scrollTo(0, ((num - 1) * pageHeight));
    };

    const handleGetHeights = () => {
        setReaderHeight({
            page: epubView[0]?.clientHeight || 0,
            scroll: epubView[0]?.scrollHeight || 0,
        });
    };

    const handleSendPercentage = (percent: number) => {
        setPercentage(percent);
    };

    return (
        <EpubWrap>
            <EpubViewWrapper>
                <EpubView
                    url={bookUrl}
                    loadingView={<LoaderWrap><Loader /></LoaderWrap>}
                    locationChanged={() => setTimeout(handleGetHeights)}
                    getRendition={(rendition) => {
                        // eslint-disable-next-line no-param-reassign
                        rendition.settings.flow = 'scrolled';
                        // eslint-disable-next-line no-param-reassign
                        rendition.settings.minSpreadWidth = Number(theme.width.wrap);
                        rendition.themes.default(epubViewStyles);
                        rendition.on('relocated', () => {
                            const { scrollTop, scrollHeight, clientHeight } = epubView[0];
                            const currentPercentage: number = (
                                scrollTop / (scrollHeight - clientHeight)
                            ) * 100;
                            handleSendPercentage(Math.round(currentPercentage));
                        });
                    }}
                />
            </EpubViewWrapper>
            {isReady
            && (
                <ProgressPagination
                    value={currentPage}
                    maxValue={pageCount}
                    onChange={handleChangePage}
                />
            )}
        </EpubWrap>
    );
};

EpubReader.defaultProps = {
    setIsReadyStatus: () => {},
};
