import React, { createRef } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash.isempty';
import { ProductTile } from 'components';
import { LibraryType, ShortContentFragment } from 'generated/graphql';
import Breakpoints from '../../themes/constants/breakpoints';

// Styles
const BookListWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    justify-items: center;
    width: 100%;
    & > * {
        overflow: visible;
    }
    @media only screen and (${Breakpoints.MOBILE_EL}) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (${Breakpoints.TABLET_S}) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media only screen and (${Breakpoints.TABLET}) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (${Breakpoints.TABLET_L}) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        grid-template-columns: repeat(5, 1fr);
    }
    @media only screen and (${Breakpoints.LAPTOP_E}) {
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 40px;
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        grid-template-columns: repeat(7, 1fr);
    }
`;

const NoData = styled.div`
    padding-top: 10px;
    font-size: 16px;
    line-height: 20px;
    color: #666;
    margin: auto;
    max-width: 260px;
    text-align: center;
    @media only screen and (${Breakpoints.TABLET}) {
        max-width: 380px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        margin-top: 80px;
    }
`;

// Interfaces
interface IBooksList {
    data: ShortContentFragment[];
    className?: string;
    refetchCallback?: (type: LibraryType) => void;
}

// Component
export const BooksList = ({ data, className, refetchCallback }: IBooksList) => {
    const productRef = createRef<HTMLDivElement>();

    if (isEmpty(data)) {
        return (
            <NoData>
                Нет подходящих книг, попробуйте изменить настройки в фильтрах
            </NoData>
        );
    }

    return (
        <BookListWrap
            className={className}
        >
            <>
                {data?.map(({
                    name,
                    id,
                    metaContentParams,
                    previews,
                    top,
                    readed,
                    link,
                }) => (
                    <ProductTile
                        key={id}
                        id={id}
                        link={link}
                        isSlide={false}
                        name={name}
                        metaContentParams={metaContentParams}
                        previews={previews}
                        ref={productRef}
                        top={top}
                        readed={readed}
                        refetchCallback={refetchCallback}
                    />
                ))}
            </>
        </BookListWrap>
    );
};

BooksList.defaultProps = {
    className: '',
    refetchCallback: () => {},
};
