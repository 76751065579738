import Cookies from 'js-cookie';
import { getMessaging, isSupported } from 'firebase/messaging/sw';
import { getToken } from 'firebase/messaging';
import CookieName from 'constants/cookies';
import APILinks from 'constants/api';
import { firebaseProjectToken, firebaseVapidKey } from 'constants/firebase';

interface IFirebaseTokenFetchArgs {
    token: string
    oldToken?: string | null
    msisdn?: number | string | null
}

export const firebaseErrorLog = (error?: Error | string) => {
    const errorDescription = error || 'неизвестна';
    // eslint-disable-next-line no-console
    console.error('PUSH Уведомления Firebase. Ошибка: ', errorDescription);
};

export const firebaseTokenFetch = (args: IFirebaseTokenFetchArgs) => {
    const {
        token,
        oldToken = null,
        msisdn = null,
    } = args;

    fetch(APILinks.FIREBASE_TOKEN, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Project-Token': firebaseProjectToken,
        },
        body: JSON.stringify({
            device: 'web',
            token,
            old_token: oldToken,
            msisdn,
        }),
    })
        .then(({ ok, status }) => {
            if (ok) {
                Cookies.set(CookieName.FIREBASE_TOKEN, token);
                if (msisdn) {
                    Cookies.set(CookieName.FIREBASE_MSISDN, `${msisdn}`);
                }
            } else {
                firebaseErrorLog(`Статус ошибки: ${status}`);
            }
        })
        .catch(firebaseErrorLog);
};

export const firebaseRegistration = (registration: ServiceWorkerRegistration) => {
    const messaging = getMessaging();

    isSupported()
        .then(async () => {
            getToken(messaging, {
                vapidKey: firebaseVapidKey,
                serviceWorkerRegistration: registration,
            })
                .then((token) => {
                    const oldToken = Cookies.get(CookieName.FIREBASE_TOKEN);
                    const msisdn = Cookies.get(CookieName.FIREBASE_MSISDN) || null;
                    const isOldToken = token === oldToken;

                    if (!isOldToken) {
                        firebaseTokenFetch({ token, oldToken, msisdn });
                    }
                })
                .catch(firebaseErrorLog);
        })
        .catch(firebaseErrorLog);
};
