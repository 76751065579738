export const getTimerFromSeconds = (seconds: number) => {
    const timeH = Math.floor((seconds / 60 / 60) % 60);
    const timeHH = `${timeH}`.length === 1 ? `0${timeH}` : timeH;
    const timeM = Math.floor((seconds / 60) % 60);
    const timeMM = `${timeM}`.length === 1 ? `0${timeM}` : timeM;
    const timeS = Math.floor(seconds % 60);
    const timeSS = `${timeS}`.length === 1 ? `0${timeS}` : timeS;

    if (timeH !== 0) {
        return `${timeHH}:${timeMM}:${timeSS}`;
    }
    return `${timeMM}:${timeSS}`;
};

export const getTime = () => {};
