import React, {
    useState, useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
    PromoBannerItem,
} from 'components';
import promoImg1 from '../../media/images/promo-1.png';
import promoImg2 from '../../media/images/promo-2.png';
import promoImg3 from '../../media/images/promo-3.png';
import Breakpoints from '../../themes/constants/breakpoints';

const Container = styled.div<{ booksPagePaddings?: boolean, newBookPaddings?: boolean }>`
  width: 100%;
  max-width: ${({ theme }) => theme.width.wrap};
  padding: ${({ newBookPaddings }) => (newBookPaddings ? '30px 20px 0' : '0 20px')};
  margin: ${({ newBookPaddings }) => (newBookPaddings ? '0 auto' : '0 auto 25px')};
  -webkit-tap-highlight-color: transparent;
  @media only screen and (${Breakpoints.TABLET}) {
      padding: ${({ booksPagePaddings }) => (booksPagePaddings ? '0 20px' : '0 40px')};
      padding: ${({ newBookPaddings }) => (newBookPaddings && '30px 0 0')};
  }
`;

type Item = {
    image: string;
    text: string;
    rightSideText?: string;
    rightSideImage?: boolean;
    leftSideImage?: boolean;
};

const items: Item[] = [
    { image: promoImg1, text: 'Читай под\nмузыку', rightSideText: 'ТОЛЬКО\nХИТЫ' },
    { image: promoImg2, text: 'Читай под\nмузыку\nТУТ', leftSideImage: true },
    { image: promoImg3, text: 'Читай под\nмузыку', rightSideImage: true },
];

interface IPromoBanner {
    booksPagePaddings?: boolean;
    newBookPaddings?: boolean;
}

export const PromoBanner = (props: IPromoBanner) => {
    const { booksPagePaddings, newBookPaddings } = props;
    const [randomItem, setRandomItem] = useState<Item | null>(null);
    const { pathname } = useLocation();

    useEffect(() => {
        const getRandomItem = () => {
            const shuffledItems = [...items].sort(() => 0.5 - Math.random());
            setRandomItem(shuffledItems[0]);
        };

        getRandomItem();
    }, [pathname]);

    return (
        <Container
            booksPagePaddings={booksPagePaddings}
            newBookPaddings={newBookPaddings}
        >
            {randomItem && (
                <PromoBannerItem
                    image={randomItem.image}
                    text={randomItem.text}
                    rightSideText={randomItem.rightSideText}
                    leftSideImage={randomItem.leftSideImage}
                    rightSideImage={randomItem.rightSideImage}
                />
            )}
        </Container>
    );
};
