import React from 'react';
import styled from 'styled-components';
import hexToRgba from '../../helpers/hexToRgba';
import Breakpoints from '../../themes/constants/breakpoints';

const ButtonStyle = styled.button`
    font-family: ${({ theme }) => theme.fontFamily.serif};
    font-size: 14px;
    font-weight: 600;
    padding: 11px 16px;
    background: ${({ theme }) => hexToRgba(theme.palette.primary, '0.8')};
    border: 0;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 30px;
        padding: 28px 34px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        font-size: 14px;
        padding: 11px 16px;
    }
`;

export interface IButton {
    onClick?: () => void;
    text: string;
    className?: string;
}

export const Button = ({ onClick, text, className }: IButton) => (
    <ButtonStyle type="button" onClick={onClick} className={className}>
        {text}
    </ButtonStyle>
);

Button.defaultProps = {
    className: '',
    onClick: () => {},
};
