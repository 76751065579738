import { useVerifyQuery } from '../generated/graphql';

export default () => {
    const { data: verifyData } = useVerifyQuery();

    return (callback: Function) => () => {
        if (verifyData?.point?.verify) {
            callback();
            return;
        }

        if (verifyData?.point?.flowURL) window.location.href = verifyData.point.flowURL;
    };
};
