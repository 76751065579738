import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Inner } from 'components';
import { Link } from 'react-router-dom';
import Breakpoints from '../../themes/constants/breakpoints';
import APILinks from '../../constants/api';

// Styles
const FooterWrap = styled.footer<{ background?: string }>`
    font-size: 11px;
    font-weight: 300;
    color: ${({ theme }) => theme.color.gray};
    margin-top: auto;
    background: ${({ theme, background }) => background
        || `linear-gradient(180deg, rgba(255, 255, 255, 0), ${theme.background.footer})`};
    z-index: 10;
    p {
        text-align: justify;
        margin: 0;
    }
    a {
        text-decoration: underline;
        &,
        &:link,
        &:visited,
        &:hover,
        &:active {
            color: ${({ theme }) => theme.palette.blue};
        }
    }
    & > div {
        display: block;
        padding: 10px 30px;
        z-index: 10;
        @media only screen and (${Breakpoints.TABLET}) {
            font-size: 13px;
        }
        @media only screen and (${Breakpoints.LAPTOP}) {
            text-align: center;
            p {
                text-align: center;
            }
        }
    }
    
`;
const LinksWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    @media only screen and (${Breakpoints.MOBILE_EL}) {
      flex-direction: row;
    }
`;

// Interfaces
export interface IFooter {
    background?: string;
}

export interface IFooterState {
    footer: string;
    contacts: string;
}

// Component
export const Footer = (props: IFooter) => {
    const [state, setState] = useState<IFooterState>({
        footer: '',
        contacts: '',
    });

    useEffect(() => {
        fetch(APILinks.FOOTER)
            .then((res) => res.text())
            .then((text) => {
                setState((s) => ({
                    ...s,
                    footer: text,
                }));
            })
            .catch(() => {});
        fetch(APILinks.FOOTER_UNDER)
            .then((res) => res.text())
            .then((text) => {
                setState((s) => ({
                    ...s,
                    contacts: text,
                }));
            });
    }, []);

    const {
        background,
    } = props;

    return (
        <FooterWrap
            background={background}
        >
            <Inner>
                <LinksWrapper>
                    <Link to="/oferta">Пользовательское соглашение</Link>
                    <Link to="/privacypolicy">Политика обработки персональных данных</Link>
                </LinksWrapper>
                <br />
                {/* eslint-disable react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: state.footer }} />
                <br />
                <div dangerouslySetInnerHTML={{ __html: state.contacts }} />
            </Inner>
        </FooterWrap>
    );
};

Footer.defaultProps = {
    background: '',
};
