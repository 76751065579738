import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import AddToHomeScreen from 'components/AddToHomeScreen/AddToHomeScreen';

const Wrap = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    display: flex;
    flex-flow: column nowrap;
    z-index: 101;
`;

function Banners() {
    const portal = document.getElementById('banners-portal');

    const el = (
        <Wrap>
            <AddToHomeScreen />
        </Wrap>
    );

    if (portal) return createPortal(el, portal);
    return null;
}

export default Banners;
