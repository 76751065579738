import {
    LibraryType, useAddLibraryTypeMutation, useRemoveLibraryTypeMutation,
} from '../generated/graphql';

interface IProps {
    id: string;
    type: LibraryType;
    callback?: () => void;
}

const useChangeLibraryType = ({ id, type, callback }: IProps): [() => void, () => void] => {
    const [addLibraryType] = useAddLibraryTypeMutation({
        variables: {
            id,
            type,
        },
        onCompleted: () => (callback && callback()),
    });

    const [removeLibraryType] = useRemoveLibraryTypeMutation({
        variables: {
            id,
            type,
        },
        onCompleted: () => (callback && callback()),
    });

    return [addLibraryType, removeLibraryType];
};

export default useChangeLibraryType;
