import { useHistory } from 'react-router';
import { useVerifyQuery } from '../generated/graphql';

export default () => {
    const history = useHistory();

    const { data: verifyData } = useVerifyQuery();

    return (link: string) => () => {
        if (verifyData?.point?.verify) {
            history.push(link);
            return;
        }

        if (verifyData?.point?.flowURL) window.location.href = verifyData.point.flowURL;
    };
};
