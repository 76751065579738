enum CompilationAliases {
    POPULAR = 'populyarnoe',
    NEW = 'novinki',
    RECOMMEND = 'rekomenduem',
    FOR_PARENTS = 'dlya-roditelei',
    BANNERS = 'banners',
    ALL = 'all-books',
}

export default CompilationAliases;
