import React, { ReactElement } from 'react';
import {
    Container, Header, Inner, IHeader, IContainer,
} from 'components';

// Interfaces
interface IDefaultLayout {
    children: ReactElement | ReactElement[];
    header?: IHeader;
    container?: IContainer;
}

// Component
const DefaultLayout = ({ children, header, container }: IDefaultLayout) => {
    const title = header?.title || '';
    const background = container?.background || '';

    return (
        <>
            <Header
                title={title}
            />
            <Container
                background={background}
                withHeader
            >
                <Inner>
                    {children}
                </Inner>
            </Container>
        </>
    );
};

DefaultLayout.defaultProps = {
    header: {
        title: '',
    },
    container: {
        background: '',
    },
};

export default DefaultLayout;
