import React, { forwardRef, Ref } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { Authors } from 'components';
import Breakpoints from '../../themes/constants/breakpoints';
import truncate from '../../helpers/truncate';
import { PreviewTypes } from '../../redux/types';
import useAuthAction from '../../hooks/useAuthAction';
import {
    LibraryType,
    ShortContentFragment,
    useAddLibraryTypeMutation,
    useRemoveLibraryTypeMutation,
} from '../../generated/graphql';
import getMetaParams from '../../helpers/getMetaParams';

import { ReactComponent as CheckIcon } from '../../media/icons/check.svg';
import { ReactComponent as CheckThinIcon } from '../../media/icons/check-thin.svg';
import { ReactComponent as StarIcon } from '../../media/icons/star-old.svg';
import { ReactComponent as StarThinIcon } from '../../media/icons/star-thin.svg';
import line from '../../media/images/line.png';
import placeholder from '../../media/images/placeholder.png';

// Styles
const Tile = styled.div<{ isSlide?: boolean }>`
    position: relative;
    height: 190px;
    width: 130px;
    border-radius: 4px;
    user-select: none;
    padding: 15px 15px 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${({ theme }) => theme.palette.white};
    background-size: cover;
    background-position: 50% center;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
    box-shadow: -4px 4px 6px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0) 40.1%,
        rgba(0, 0, 0, 0.14) 57.29%,
        rgba(0, 0, 0, 0.7) 100%);
        z-index: -1;
    }
    @media only screen and (min-width: 360px) {
        height: 200px;
        width: 140px;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        height: ${({ isSlide }) => (isSlide ? '377px' : '308px')};
        width: ${({ isSlide }) => (isSlide ? '264px' : '216px')};
        padding: 30px 30px 15px;
        border-radius: 9px;
        box-shadow: -9px 9px 12.8px rgba(0, 0, 0, 0.2);
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        height: 228px;
        width: 160px;
        padding: 20px 20px 7px;
    }
`;
const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: -15px -15px 0;
    padding: 15px 15px 0;
    cursor: pointer;
    flex: 1 1;
    width: calc(100% + 30px);
    @media only screen and (${Breakpoints.TABLET}) {
        margin: -30px -30px 0;
        width: calc(100% + 60px);
        padding: 30px 30px 0;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        margin: -20px -20px 0;
        width: calc(100% + 40px);
        padding: 20px 20px 0;
    }
`;
const Title = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.serif};
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    text-transform: uppercase;
    background: transparent;
    border: none;
    color: inherit;
    text-align: left;
    margin-bottom: auto;
    width: 100%;
    overflow-wrap: break-word;
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 20px;
        line-height: 25px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        font-size: 11px;
        line-height: 15px;
    }
`;
const StyledAutors = styled(Authors)`
    p {
        font-size: 12px;
        line-height: 1.15;
        margin: 4px 0 0;
        @media only screen and (${Breakpoints.TABLET}) {
            font-size: 25px;
        }
        @media only screen and (${Breakpoints.LAPTOP}) {
            font-size: 16px;
        }
    }
`;
const ButtonsWrapper = styled.div`
    align-self: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px -7px 0;
    @media only screen and (${Breakpoints.TABLET}) {
        margin-left: -14px;
        margin-right: -14px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        font-size: 16px;
        margin-left: -7px;
        margin-right: -7px;
    }
`;
const Button = styled.button<{ active?: boolean }>`
    cursor: pointer;
    height: 30px;
    width: 30px;
    background: transparent;
    border: none;
    fill: ${({ active, theme }) => (active ? theme.palette.primary : theme.palette.white)};
    stroke: ${({ active, theme }) => (active ? theme.palette.primary : theme.palette.white)};
    @media only screen and (${Breakpoints.TABLET}) {
        height: 57px;
        width: 57px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        height: 30px;
        width: 30px;
    }
`;
const StyledCheckThinIcon = styled(CheckThinIcon)`
    @media only screen and (${Breakpoints.TABLET}) {
        width: 23px;
        height: 20px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        width: 16px;
        height: 14px;
    }
`;
const StyledCheckIcon = styled(CheckIcon)`
    @media only screen and (${Breakpoints.TABLET}) {
        width: 23px;
        height: 20px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        width: 16px;
        height: 14px;
    }
`;
const StyledStarThinIcon = styled(StarThinIcon)`
    @media only screen and (${Breakpoints.TABLET}) {
        width: 38px;
        height: 38px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        width: 22px;
        height: 20px;
    }
`;
const StyledStarIcon = styled(StarIcon)`
    @media only screen and (${Breakpoints.TABLET}) {
        width: 38px;
        height: 38px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        width: 22px;
        height: 20px;
    }
`;
const Line = styled.img`
    position: absolute;
    width: auto;
    height: 100%;
    left: 4px;
    bottom: -5px;
    display: block;
`;

// Interfaces
export interface IProductTile extends ShortContentFragment {
    className?: string;
    isSlide: boolean;
    refetchCallback?: (type: LibraryType) => void;
}

// Component
export const ProductTile = forwardRef(({
    id,
    className,
    isSlide,
    name,
    metaContentParams,
    previews,
    top,
    readed,
    refetchCallback,
}: IProductTile, ref: Ref<HTMLDivElement>) => {
    const history = useHistory();
    const authAction = useAuthAction();

    const creators = getMetaParams(metaContentParams, 'creator') || [];
    const coverLink = previews?.find((i) => i.type === PreviewTypes.MAIN)?.link;
    const cover = coverLink ? `/gate/f/${coverLink}` : placeholder;

    const [removeReadNowType] = useRemoveLibraryTypeMutation({
        variables: {
            id,
            type: LibraryType.ReadNow,
        },
        onCompleted: () => refetchCallback && refetchCallback(LibraryType.ReadNow),
    });

    const [addReadedType] = useAddLibraryTypeMutation({
        variables: {
            id,
            type: LibraryType.Readed,
        },
    });

    const [removeReadedType] = useRemoveLibraryTypeMutation({
        variables: {
            id,
            type: LibraryType.Readed,
        },
        onCompleted: () => refetchCallback && refetchCallback(LibraryType.Readed),
    });

    const [addTopType] = useAddLibraryTypeMutation({
        variables: {
            id,
            type: LibraryType.Top,
        },
    });

    const [removeTopType] = useRemoveLibraryTypeMutation({
        variables: {
            id,
            type: LibraryType.Top,
        },
        onCompleted: () => refetchCallback && refetchCallback(LibraryType.Top),
    });

    return (
        <Tile
            className={classNames(className, isSlide ? 'swiper-lazy' : null)}
            data-background={isSlide ? cover : null}
            style={isSlide ? {} : { backgroundImage: `url(${cover}` }}
            ref={ref}
            isSlide={isSlide}
        >
            <Line src={line} alt="" />
            <TitleWrapper onClick={() => history.push(`/books/${id.replace('content/', '')}`)}>
                <Title>
                    {truncate(name, 38)}
                </Title>
                {creators && <StyledAutors creators={creators} maxToShow={2} />}
            </TitleWrapper>
            <ButtonsWrapper>
                <Button
                    active={readed}
                    type="button"
                    onClick={authAction(() => {
                        if (readed) {
                            removeReadedType();
                        } else {
                            addReadedType();
                            removeReadNowType();
                        }
                    })}
                >
                    {readed ? <StyledCheckIcon /> : <StyledCheckThinIcon />}
                </Button>
                <Button
                    active={top}
                    type="button"
                    onClick={authAction(() => (top ? removeTopType() : addTopType()))}
                >
                    {top ? <StyledStarIcon /> : <StyledStarThinIcon />}
                </Button>
            </ButtonsWrapper>
        </Tile>
    );
});

ProductTile.defaultProps = {
    className: '',
    refetchCallback: () => {},
};
