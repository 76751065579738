import React, { ReactElement } from 'react';
import { ThemeProvider as Provider } from 'styled-components';
import theme from '../constants/themes';
import MainCSS from '../styles/MainCSS';
import 'normalize.css';
import '../../media/fonts/stylesheet.css';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/lazy/lazy.scss';

// Interfaces
interface IThemeProvider {
    children: ReactElement | ReactElement[]
}

// Component
const ThemeProvider = (props: IThemeProvider) => {
    const { children } = props;

    // Render
    return (
        <Provider theme={theme}>
            <MainCSS />
            {children}
        </Provider>
    );
};

export default ThemeProvider;
