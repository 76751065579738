import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { HeaderDesktopMenuItem } from 'components';
import menus from '../../constants/menus';

// Styles
const Wrap = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    height: inherit;
    margin: 0;
    z-index: 1000;
`;

// Component
export const HeaderDesktopMenu = () => {
    const history = useHistory();

    const menuItems = menus.map((item) => {
        const {
            title: itemTitle,
            path,
            icon,
            id,
            hasSubMenus = false,
        } = item;

        const handleRedirect = (pathName: string) => history.push(pathName);

        return (
            <HeaderDesktopMenuItem
                title={itemTitle}
                path={path}
                icon={icon}
                handleRedirect={handleRedirect}
                hasSubMenus={hasSubMenus}
                key={id}
            />
        );
    });

    return (
        <Wrap>
            {menuItems}
        </Wrap>
    );
};
