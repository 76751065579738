import { MetaContentParam } from 'generated/graphql';
import { Base64 } from 'js-base64';

export default (params: MetaContentParam[] | undefined | null, name: string) => {
    const decodedValue = Base64
        .fromBase64(params?.find((i) => i.name === name)?.value || '');
    try {
        return JSON.parse(decodedValue);
    } catch {
        return decodedValue;
    }
};
