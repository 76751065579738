import { ElementType } from 'react';
import RoutePath from '../routes/constants/routes';
import { ReactComponent as HomeIcon } from '../media/icons/home.svg';
import { ReactComponent as AllBooksIcon } from '../media/icons/all-books.svg';
import { ReactComponent as PopularIcon } from '../media/icons/popular.svg';
import { ReactComponent as NewIcon } from '../media/icons/new.svg';
import { ReactComponent as RecommendedIcon } from '../media/icons/recommended.svg';
import { ReactComponent as ParentsIcon } from '../media/icons/parents.svg';
import { ReactComponent as CategoriesIcon } from '../media/icons/categories.svg';
import { ReactComponent as InfoIcon } from '../media/icons/info.svg';

// Interfaces
export interface IMenuItem {
    title: string
    icon: ElementType
    path: RoutePath
    id: number
}
export interface IMenuSubItem {
    title: string
    path: string
    id: number
}
interface IMenuElement extends IMenuItem {
    hasSubMenus?: boolean
}

const menus: IMenuElement[] = [
    {
        title: 'Главная',
        path: RoutePath.ROOT_PATH,
        icon: HomeIcon,
        id: 1,
    },
    {
        title: 'Все книги',
        path: RoutePath.BOOKS_PATH,
        icon: AllBooksIcon,
        id: 2,
    },
    {
        title: 'Популярное в мире',
        path: RoutePath.POPULAR_PATH,
        icon: PopularIcon,
        id: 3,
    },
    {
        title: 'Новинки',
        path: RoutePath.NEW_PATH,
        icon: NewIcon,
        id: 4,
    },
    {
        title: 'Рекомендуем',
        path: RoutePath.RECOMMEND_PATH,
        icon: RecommendedIcon,
        id: 5,
    },
    {
        title: 'Для родителей',
        path: RoutePath.FOR_PARENTS_PATH,
        icon: ParentsIcon,
        id: 6,
    },
    {
        title: 'Категории',
        path: RoutePath.CATEGORIES_PATH,
        icon: CategoriesIcon,
        id: 7,
        hasSubMenus: true,
    },
    {
        title: 'Информация',
        path: RoutePath.INFO_PATH,
        icon: InfoIcon,
        id: 8,
    },
];

export default menus;
