enum Breakpoints {
    MOBILE_L = 'min-width: 425px',
    MOBILE_EL = 'min-width: 500px',
    TABLET_S = 'min-width: 660px',
    TABLET = 'min-width: 768px',
    TABLET_L = 'min-width: 900px',
    LAPTOP = 'min-width: 1024px',
    LAPTOP_E = 'min-width: 1250px',
    LAPTOP_L = 'min-width: 1440px',
    DESKTOP_S = 'min-width: 1700px',
    DESKTOP_M = 'min-width: 1920px',
}

export default Breakpoints;
