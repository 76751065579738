import styled from 'styled-components';

export const ButtonMain = styled.button`
    height: 60px;
    padding: 0 15px;
    font-size: 21px;
    font-weight: 700;
    background: ${({ theme }) => theme.palette.primary};
    border-radius: 10px;
    color: ${({ theme }) => theme.palette.white};
    width: 100%;
`;
