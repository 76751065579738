import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Logo } from '../../media/icons/logo.svg';
import RoutePath from '../../routes/constants/routes';
import Breakpoints from '../../themes/constants/breakpoints';

// Styles
const LogoButton = styled.div`
    background: none;
    border: 0;
    height: inherit;
    cursor: pointer;
    font-size: 20px;
    line-height: 26px;
    color: ${({ theme }) => theme.color.primary};
    font-weight: 700;
    display: flex;
    align-items: center;
    margin: auto 0;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;
    @media only screen and (${Breakpoints.LAPTOP}) {
        margin-right: 40px;
    }
    @media only screen and (${Breakpoints.DESKTOP_S}) {
        position: absolute;
        margin-right: 0;
        transform: translateX(calc(-100% - 22px));
    }
    @media only screen and (${Breakpoints.DESKTOP_M}) {
        transform: translateX(calc(-100% - 80px));
    }
`;

// Interfaces
interface IHeaderLogo {
    title?: string
}

// Component
export const HeaderLogo = (props: IHeaderLogo) => {
    const { title } = props;

    const history = useHistory();

    const isLaptopL = useMediaQuery({ query: `(${Breakpoints.LAPTOP_L})` });

    const redirectToRoot = () => history && history.push(RoutePath.ROOT_PATH);

    return (
        <LogoButton>
            {(!isLaptopL && title) || (
                <Logo onClick={redirectToRoot} />
            )}
        </LogoButton>
    );
};

HeaderLogo.defaultProps = {
    title: '',
};
