import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { HeaderLogo, HeaderMobileMenuItem } from 'components';
import menus from '../../constants/menus';
import Breakpoints from '../../themes/constants/breakpoints';

import { ReactComponent as MenuIcon } from '../../media/icons/menu.svg';

// Styles
const MenuButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    height: inherit;
    width: 56px;
    @media only screen and (${Breakpoints.TABLET}) {
        width: ${({ theme }) => theme.height.header.tablet};
    }
`;
const MenuModal = styled.div<{ open?: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: ${({ open }) => (open ? 0 : '-100vw')};
    min-width: 290px;
    width: 450px;
    max-width: calc(100vw - 70px);
    background: #fff;
    z-index: 10000;
    transition: ${({ theme }) => `
        left ${theme.transition.medium}`};
`;
const MenuMask = styled.div<{ open?: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    opacity: ${({ open }) => (open ? 1 : 0)};
    z-index: ${({ open }) => (open ? 1000 : -1000)};
    transition: ${({ theme }) => `
        opacity ${theme.transition.medium},
        z-index 0s,
        left ${theme.transition.medium}`};
`;
const MenuHeader = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    height: ${({ theme }) => theme.height.header.primary};
    margin-bottom: 10px;
    @media only screen and (${Breakpoints.TABLET}) {
        margin-bottom: 20px;
        height: ${({ theme }) => theme.height.header.tablet};
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        height: ${({ theme }) => theme.height.header.desktop};
    }
`;
const MenuList = styled.div`
    display: flex;
    flex-flow: column nowrap;
`;

// Interfaces
interface IMenu {
    open: boolean
    setOpen: (open: boolean) => void
}

// Component
export const HeaderMobileMenu = ({ open, setOpen }: IMenu) => {
    const history = useHistory();
    const portal = document.getElementById('menu-portal');

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'hidden scroll';
        }
    }, [open]);

    const handleToggleMenu = () => setOpen(!open);
    const handleCloseMenu = () => setOpen(false);

    const menuButton = (
        <MenuButton
            onClick={handleToggleMenu}
        >
            <MenuIcon />
        </MenuButton>
    );

    const menuList = menus.map((item) => {
        const {
            title: itemTitle,
            path,
            icon,
            id,
            hasSubMenus = false,
        } = item;

        const handleRedirect = (pathName: string) => {
            handleCloseMenu();
            if (history) history.push(pathName);
        };

        return (
            <HeaderMobileMenuItem
                title={itemTitle}
                path={path}
                icon={icon}
                handleRedirect={handleRedirect}
                hasSubMenus={hasSubMenus}
                setOpen={setOpen}
                key={id}
            />
        );
    });

    const menuModal = (
        <>
            <MenuMask
                onClick={handleCloseMenu}
                open={open}
            />
            <MenuModal
                open={open}
            >
                <MenuHeader>
                    {menuButton}
                    <HeaderLogo />
                </MenuHeader>
                <MenuList>
                    {menuList}
                </MenuList>
            </MenuModal>
        </>
    );

    return (
        <>
            {menuButton}
            {portal ? createPortal(menuModal, portal) : null}
        </>
    );
};
