import React from 'react';
import { createPortal } from 'react-dom';
import styled, { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Inner, Loader } from 'components';
import { SpeedButton } from '../AudioSpeedController/SpeedButton';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { AudioStatus } from '../../redux/types';
import { setAudioCurrentTime } from '../../redux/features/audio/audioSlice';

import { ReactComponent as PrevIcon } from '../../media/icons/prev.svg';
import { ReactComponent as PauseIcon } from '../../media/icons/pause.svg';
import { ReactComponent as PlayIcon } from '../../media/icons/play.svg';
import { ReactComponent as NextIcon } from '../../media/icons/next.svg';
import { ITheme } from '../../themes/constants/themes';
import Breakpoints from '../../themes/constants/breakpoints';

// Styles
const Wrap = styled(Inner)`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    height: 40px;
    background: ${({ theme }) => theme.background.audioDefault};
    box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(4px);
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding-right: 13px;
    font-size: 12px;
    line-height: 1.67;
    color: ${({ theme }) => theme.color.audioDefault};
    @media only screen and (${Breakpoints.TABLET}) {
        ${({ theme }) => theme.paddings.tablet};
    }
`;
const Caption = styled.div`
    display: flex;
    align-items: center;
    margin-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: inherit;
    cursor: pointer;
    flex-grow: 1;
`;
const Author = styled.span`
    position: relative;
    padding-right: 16px;
    &:after {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #fff;
    }
`;
const Name = styled.span`
    font-weight: 700;
`;
const Player = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    width: 105px;
`;
const PlayerButton = styled.button`
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 35px;
    color: ${({ theme }) => theme.color.audioDefault};
`;
const PlayerPlayButton = styled(PlayerButton)`
    svg {
        height: 18px;
    }
`;
const StyledSpeedButton = styled(SpeedButton)`
    margin-left: 5px;
    color: ${({ theme }) => theme.color.audioDefault};
    svg {
        fill: #fff;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        margin-left: 15px;
    }
`;

// Interfaces
interface IAudioGlobal {
    handlePlay: () => void
    handlePause: () => void
}

// Component
export const AudioGlobal = ({ handlePlay, handlePause }: IAudioGlobal) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const portal = document.getElementById('audio-player-portal');
    const audioState = useAppSelector((store) => store.audio);

    const audioStatusState = audioState.status;
    const audioAuthorName = audioState.current.authorName;
    const audioName = audioState.current.name || 'Аудиокнига не выбрана';
    const audioId = audioState.current.id;
    const isPlayStatus = audioStatusState === AudioStatus.PLAY;
    const audioLoading = audioState.loading;
    const currentTime: number = audioState.current.time?.currentTime || 0;
    const totalTime: number = audioState.current.time?.totalTime || 0;

    const theme = useTheme() as ITheme;

    const handleBackAudioTime = () => {
        const newTime = (currentTime <= 10) ? 0 : (currentTime - 10);
        dispatch(setAudioCurrentTime(newTime));
    };
    const handleForwardAudioTime = () => {
        const newTime = ((currentTime + 10) <= totalTime) ? (currentTime + 10) : totalTime;
        dispatch(setAudioCurrentTime(newTime));
    };

    const handleClick = () => history.push(`/books/${audioId}/listen`);
    const handleToggleAudioStatus = () => {
        if (!isPlayStatus) {
            handlePlay();
        } else {
            handlePause();
        }
    };

    const playIcon = isPlayStatus ? <PauseIcon /> : <PlayIcon />;

    const renderPlayer = () => (
        <>
            <Wrap>
                <Caption
                    onClick={handleClick}
                >
                    {audioAuthorName
                        && <Author>{audioAuthorName}</Author>}
                    <Name>{audioName}</Name>
                </Caption>
                <Player>
                    <PlayerButton
                        type="button"
                        onClick={handleBackAudioTime}
                    >
                        <PrevIcon />
                    </PlayerButton>
                    <PlayerPlayButton
                        type="button"
                        onClick={handleToggleAudioStatus}
                    >
                        {audioLoading
                            ? <Loader color={{ to: theme.background.audioDefault, from: '#fff' }} size={0.7} />
                            : playIcon}
                    </PlayerPlayButton>
                    <PlayerButton
                        type="button"
                        onClick={handleForwardAudioTime}
                    >
                        <NextIcon />
                    </PlayerButton>
                </Player>
                <StyledSpeedButton />
            </Wrap>
        </>
    );

    return portal ? createPortal(renderPlayer(), portal) : null;
};
