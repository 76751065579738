import styled from 'styled-components';
import Breakpoints from '../../themes/constants/breakpoints';

export interface IContainer {
    withHeader?: boolean
    customBackground?: string
    background?: string
}

export const Container = styled.div<IContainer>`
    display: flex;
    flex-flow: column nowrap;
    min-height: calc(var(--vh) * 100);
    padding: ${({
        theme, withHeader,
    }) => `${withHeader ? theme.height.header.primary : 0} 0 0`};
    background: ${({ theme, background, customBackground }) => customBackground
        || `${theme.background.primary} ${background && `url(${background}) repeat`}`};
    @media only screen and (${Breakpoints.TABLET}) {
        padding: ${({
        theme, withHeader,
    }) => `${withHeader ? theme.height.header.tablet : 0} 0 0`};
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        padding: ${({
        theme, withHeader,
    }) => `${withHeader ? theme.height.header.desktop : 0} 0 0`};
    }
`;
