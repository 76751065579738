import { configureStore } from '@reduxjs/toolkit';
import dataReducer from './features/data/dataSlice';
import audioReducer from './features/audio/audioSlice';
import selectsReducer from './features/selects/selectsSlice';

export const store = configureStore({
    reducer: {
        data: dataReducer,
        audio: audioReducer,
        selects: selectsReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
