import { ContentFragment, SearchContentFragment } from '../generated/graphql';
import getMetaParams from './getMetaParams';

export function filterContentByCompilationAndBookType<Type extends ContentFragment | SearchContentFragment>(
    content: Type[],
    compilation: string,
): Type[] {
    return content.filter((contentItem) => {
        const currentCompilation = getMetaParams(contentItem.metaContentParams, 'genres');
        return (!compilation || currentCompilation.includes(compilation)) && contentItem.type === 'book';
    });
}
