import React from 'react';
import styled from 'styled-components';
import { IAudioSpeedValue } from '../../../constants/speedValues';
import { useAppDispatch } from '../../../hooks/redux';
import { setAudioSpeed } from '../../../redux/features/audio/audioSlice';

const Wrapper = styled.button<{ active: boolean }>`
    font-size: 10px;
    line-height: 28px;
    font-weight: 700;
    color: #fff;
    padding: 0 5px;
    margin-bottom: 5px;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    background-color: ${({ active, theme }) => (active ? theme.background.buttonPrimary : 'transparent')};
`;

interface ISpeedValueButton extends IAudioSpeedValue {
    active: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const SpeedValueButton = ({
    value, label, active, setOpen,
}: ISpeedValueButton) => {
    const dispatch = useAppDispatch();
    const setSpeedValue = () => {
        if (!active) {
            dispatch(setAudioSpeed(value));
            setTimeout(() => {
                setOpen(false);
            }, 100);
        }
    };
    return (
        <Wrapper
            onClick={setSpeedValue}
            active={active}
        >
            {label}
        </Wrapper>
    );
};
