enum RoutePath {
    ROOT_PATH = '/',
    MY_BOOKS_PATH = '/my-books',
    POPULAR_PATH = '/popular',
    NEW_PATH = '/new',
    RECOMMEND_PATH = '/recommend',
    FOR_PARENTS_PATH = '/for-parents',
    CATEGORIES_PATH = '/categories',
    CATEGORIES_WITH_ALIAS_PATH = '/categories/:alias',
    SEARCH_PATH = '/search',
    BOOKS_DETAIL_PATH = '/books/:bookId',
    BOOKS_DETAIL_READ_PATH = '/books/:bookId/read',
    BOOKS_DETAIL_LISTEN_PATH = '/books/:bookId/listen',
    BOOKS_PATH = '/books',
    OFFER_PATH = '/oferta',
    INFO_PATH = '/info',
    PP_PATH = '/privacypolicy',
}

export default RoutePath;
