import React from 'react';

export interface IAuthors {
    creators: string[];
    maxToShow?: number;
    className?: string;
}

export const Authors = ({ creators, maxToShow, className }: IAuthors) => {
    if (creators.length === 0) return <></>;

    return (
        <div className={className}>
            {creators.map((i: string, index: number) => index < (maxToShow || 3) && <p key={i}>{i}</p>)}
        </div>
    );
};

Authors.defaultProps = {
    maxToShow: 3,
    className: '',
};
