import React, { useEffect } from 'react';
import { Loader } from 'components';
import RouterProvider from 'routes/provider/RouterProvider';
import { useParamsQuery } from 'generated/graphql';
import uniq from 'lodash.uniq';
import flatten from 'lodash.flatten';
import { ISelectFieldOption } from 'redux/types';
import { useAppDispatch } from 'hooks/redux';
import { setAuthors, setGenres } from 'redux/features/data/dataSlice';
import useFirebase from 'hooks/useFirebase';
import useResize from 'hooks/useResize';

export const App = () => {
    const dispatch = useAppDispatch();

    useFirebase();
    useResize();

    const { data: authorsData, loading: authorsLoading } = useParamsQuery({
        variables: {
            name: 'creator',
        },
    });

    const { data: genresData, loading: genresLoading } = useParamsQuery({
        variables: {
            name: 'genres',
        },
    });

    useEffect(() => {
        const creatorsParams = authorsData?.point?.service.params;
        const genresParams = genresData?.point?.service.params;

        if (creatorsParams?.length) {
            const creatorsObj: string[] = creatorsParams
                .map((i) => JSON.parse(decodeURIComponent(escape(atob(i.value)))));

            const creatorsArr: ISelectFieldOption[] = uniq(flatten(creatorsObj))
                .sort((one, two) => (one > two ? 1 : -1)).map((i) => ({
                    value: i,
                    label: i,
                }));

            dispatch(setAuthors(creatorsArr));
        }

        if (genresParams?.length) {
            const genresObj: string[] = genresParams
                .map((i) => JSON.parse(decodeURIComponent(escape(atob(i.value)))));

            dispatch(setGenres(['Все категории', ...uniq(flatten(genresObj))]));
        }
    }, [authorsData, genresData]);

    if (authorsLoading || genresLoading) return <Loader />;

    return <RouterProvider />;
};
