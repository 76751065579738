export default (key: string): string => {
    switch (key) {
        case 'Все':
            return 'all';
        case 'Саморазвитие':
            return 'education';
        case 'Бизнес':
            return 'business';
        case 'Технологии':
            return 'innovations';
        case 'Общество':
            return 'society';
        case 'Семья':
            return 'family';
        case 'ЗОЖ':
            return 'health';
        case 'Деньги':
            return 'finance';
        default:
            return '';
    }
};
