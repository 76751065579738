import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDataState, ISelectFieldOption } from '../../types';

const initialState: IDataState = {
    isError: false,
    isLoading: {
        status: true,
        library: true,
    },
    footerText: '',
    infoText: '',
    contacts: '',
    isAuth: false,
    authors: [],
    genres: [],
};

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setAuthors: (state, action: PayloadAction<ISelectFieldOption[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.authors = action.payload;
        },
        setGenres: (state, action: PayloadAction<string[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.genres = action.payload;
        },
    },
});

export const { setAuthors, setGenres } = dataSlice.actions;

export default dataSlice.reducer;
