import React from 'react';
import SwiperCore, {
    Pagination,
    Lazy,
    Autoplay,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Button, Loader, Inner } from 'components';
import { useContentByCategoryQuery, MetaContentParam } from '../../generated/graphql';
import Breakpoints from '../../themes/constants/breakpoints';

SwiperCore.use([Pagination, Lazy, Autoplay]);

export interface ISliderMain {
    categoryId: string;
}

// Styles
const Slider = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    & .swiper-container {
        width: 100%;
        @media only screen and (${Breakpoints.MOBILE_L}) {
            max-width: 515px;
        }
        @media only screen and (${Breakpoints.TABLET}) {
            max-width: 750px;
        }
        @media only screen and (${Breakpoints.LAPTOP}) {
            width: 1416px;
            max-width: 1416px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    & .swiper-pagination-bullet {
        height: 6px;
        width: 6px;
        background: ${({ theme }) => theme.palette.white};
        opacity: 1;
        margin: 0 5px !important;
    }
    & .swiper-pagination-bullet-active {
        background: ${({ theme }) => theme.palette.primary} !important;
    }
    & .swiper-pagination {
        bottom: 17px;
        right: 160px;
        left: 15px;
        width: auto;
        text-align: left;
    }
    & .swiper-slide {
        @media only screen and (${Breakpoints.LAPTOP}) {
            width: 480px;
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #fff;
                opacity: 0;
                z-index: 10;
                transition: all .6s ease-in-out;
                pointer-events: none;
            }

            &:not(.swiper-slide-active) {
                transition: all .6s ease-in-out;
                transform: scale(0.93);

                &:after {
                    opacity: .85;
                }
            }
        }
    }
    @media only screen and (${Breakpoints.MOBILE_L}) {
        & .swiper-pagination {
            bottom: 30px;
            left: 30px;
        }
    }
    @media only screen and (${Breakpoints.TABLET}) {
        padding-top: 40px;
        padding-bottom: 40px;
        & .swiper-pagination-bullet {
            height: 13px;
            width: 13px;
            margin: 0 11px !important;
        }
        & .swiper-pagination {
            bottom: 40px;
            right: 160px;
            left: 30px;
        }
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        & .swiper-container {
            padding-bottom: 40px;
        }
        & .swiper-pagination {
            bottom: 0;
            left: 50%;
            right: auto;
            text-align: center;
            transform: translateX(-50%);
        }
        & .swiper-pagination-bullet {
            background: #cfcfcf;
            height: 10px;
            width: 10px;
            margin: 0 10px !important;
        }
    }
`;
const SwiperSpacer = styled.div`
    height: 0;
    width: 100%;
    padding-top: 62.5%;
    position: relative;
`;
const SwiperSlideInner = styled.div`
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50% center;
    padding-top: 25px;
    padding-bottom: 25px;
    ${({ theme }) => theme.paddings.primary};
    border-radius: 10px;
    cursor: default;
    z-index: 2;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(0,0,0, 60%), rgba(0,0,0, 0%));
        z-index: -1;
        pointer-events: none;
    }
    @media only screen and (${Breakpoints.MOBILE_L}) {
        padding: 35px 30px 30px;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        padding-top: 50px;
        padding-bottom: 40px;
        ${({ theme }) => theme.paddings.tablet};
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        padding-top: 25px;
        padding-bottom: 25px;
        ${({ theme }) => theme.paddings.primary};
    }
`;
const SlideTitle = styled.h3`
    font-family: ${({ theme }) => theme.fontFamily.serif};
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    margin-top: 0;
    @media only screen and (${Breakpoints.MOBILE_L}) {
        font-size: 20px;
        line-height: 26px;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 34px;
        line-height: 42px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        font-size: 26px;
        line-height: 30px;
    }
`;
const SlideText = styled.h3`
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #ffffff;
    @media only screen and (${Breakpoints.MOBILE_L}) {
        font-size: 16px;
        line-height: 20px;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 25px;
        line-height: 32px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        font-size: 12px;
        line-height: 15px;
    }
`;
const SliderButton = styled(Button)`
    position: absolute;
    right: 30px;
    bottom: 10px;
    @media only screen and (${Breakpoints.MOBILE_L}) {
        right: 35px;
        bottom: 20px;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        right: 40px;
        bottom: 30px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        right: 25px;
        bottom: 20px;
    }
`;
const CustomInner = styled(Inner)`
    overflow: hidden;
`;

// Component
export const SliderMain = ({ categoryId }: ISliderMain) => {
    const history = useHistory();

    const { data, loading } = useContentByCategoryQuery({
        variables: {
            id: categoryId,
        },
    });

    const contents = data?.point?.service?.category?.contents;

    const handleButton = (params: MetaContentParam[] | null | undefined) => () => {
        if (params) {
            const url = params.find((param) => param.name === 'url')?.value;

            if (url) history.push(`/${JSON.parse(atob(url))}`);
        }
    };

    if (loading) return <Loader />;

    return (
        <Slider>
            <CustomInner>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    watchOverflow
                    pagination={{
                        clickable: true,
                    }}
                    loop={contents ? contents.length > 1 : false}
                    lazy={{
                        loadPrevNext: true,
                        loadPrevNextAmount: 1,
                    }}
                    autoplay={{
                        delay: 6500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        1024: {
                            slidesPerView: 3,
                            centeredSlides: true,
                        },
                    }}
                >
                    {contents?.map((item) => {
                        const {
                            id, name, description, previews, metaContentParams,
                        } = item;

                        if (!item.previews?.length) return null;

                        return (
                            <SwiperSlide
                                key={id}
                                onClick={handleButton(metaContentParams)}
                            >
                                <SwiperSlideInner
                                    data-background={
                                        previews?.[0]?.link ? `/gate/f/${previews?.[0]?.link}` : null
                                    }
                                    className="swiper-lazy"
                                >
                                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
                                    <SlideTitle>
                                        {name}
                                    </SlideTitle>
                                    <SlideText>
                                        {description}
                                    </SlideText>
                                    <SliderButton
                                        text="Подробнее"
                                    />
                                </SwiperSlideInner>
                                <SwiperSpacer />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </CustomInner>
        </Slider>
    );
};
